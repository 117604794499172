import { Injectable, OnInit } from '@angular/core';
import {
    ApiResponse,
    DiscontinuedProductFilterReq, DiscontinuedProductFilterResp,
    Information,
    InformationSearch,
    InformationTags,
    PagedResp
} from "../api/types";
import { BaseService } from '../base/base.service';
import { HttpService } from '../api/http.service';
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
import { isNullOrUndefined } from "../common/utils";
import { HttpParamEncoder } from "../common/encoder/http-param.encoder";

@Injectable()
export class InformationService extends BaseService implements OnInit {
    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }

    /**
     * 获取资讯列表
     * @param search 搜索参数
     */
    list(search: InformationSearch): Observable<PagedResp<Information>> {
        let params = new HttpParams({encoder: new HttpParamEncoder()});        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.id)) {
            params = params.set('id', search.id.toString());
        }
        if (!isNullOrUndefined(search.keyword)) {
            params = params.set('keyword', search.keyword.toString());
        }
        if (!isNullOrUndefined(search.type)) {
            params = params.set('type', search.type.toString());
        }
        if (!isNullOrUndefined(search.tags)) {
            params = params.set('tags', search.tags.toString());
        }
        if (!isNullOrUndefined(search.beginTime)) {
            params = params.set('beginTime', search.beginTime.toString());
        }
        if (!isNullOrUndefined(search.endTime)) {
            params = params.set('endTime', search.endTime.toString());
        }
        return this.http.get<ApiResponse<PagedResp<Information>>>(this.informationUrl + '/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    /**
     * 获取資訊詳情
     * @param id 资讯ID
     */
    info(id: number): Observable<Information> {
        return this.http.get<ApiResponse<Information>>(this.informationUrl + '/' + id, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    tags(): Observable<InformationTags> {
        return this.http.get<ApiResponse<InformationTags>>(this.informationUrl + '/tags/all', {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    all(): Observable<Information> {
        return this.http.get<ApiResponse<Information>>(this.informationUrl + '/all', {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    create(information: Information): Observable<Information> {
        return this.http.post<ApiResponse<Information>>(this.informationUrl + '/create', information, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    update(information: Information): Observable<Information> {
        return this.http.put<ApiResponse<Information>>(this.informationUrl + '/update', information, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    delete(id: number): Observable<string> {
        return this.http.delete<ApiResponse<string>>(this.informationUrl + '/' + id, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    tagsUpdate(tags: string[]): Observable<string[]> {
        return this.http.put<ApiResponse<string[]>>(this.informationUrl + '/tags/update', {name:tags}, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    filterDiscontinuedProducts(req: DiscontinuedProductFilterReq): Observable<DiscontinuedProductFilterResp> {
        return this.http.post<ApiResponse<DiscontinuedProductFilterResp>>(this.informationUrl + '/filter-discontinued-products', req, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    export(): Observable<boolean> {
        return this.http.download(this.informationUrl + '/export', {
            headers: this.getHeaders(),
            params: null,
            responseType: 'blob',
            observe: 'response'
        }).pipe(map(response => {
            this.http.saveResponseBlob(response);
            return true;
        }))
    }
}
