import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {Currency, ProposalSearch} from "../../../api/types";
import { PropertySelectOption } from "../../../shared/components/property-select/property-select.component";
import { ProposalService } from "../../proposal.service";
import {MetadataService} from "../../../shared/services/metadata.service";
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-proposal-search',
    templateUrl: './proposal-search.component.html',
    styleUrls: ['./proposal-search.component.less']
})
export class ProposalSearchComponent implements OnInit {

    search: ProposalSearch = new ProposalSearch();

    genderOptions: PropertySelectOption[] = [];
    smokeOptions: PropertySelectOption[] = [];
    currencyOptions: PropertySelectOption[] = [];

    cleanedOptions: PropertySelectOption[] = []
    abnormalOptions: PropertySelectOption[] = []
    time: Date[] = null;


    @Output()
    proposalSearch: EventEmitter<ProposalSearch> = new EventEmitter<ProposalSearch>();

    constructor(private proposalService: ProposalService, private metadataService: MetadataService) {
    }

    ngOnInit(): void {
        this.genderOptions?.push(new PropertySelectOption('男', "MALE"));
        this.genderOptions?.push(new PropertySelectOption('女', "FEMALE"));

        this.smokeOptions?.push(new PropertySelectOption('吸煙', "S"));
        this.smokeOptions?.push(new PropertySelectOption('非吸煙', "NS"));
        this.cleanedOptions?.push(new PropertySelectOption('全部', null));
        this.cleanedOptions?.push(new PropertySelectOption('已清理', true));
        this.cleanedOptions?.push(new PropertySelectOption('未清理', false));
        this.abnormalOptions?.push(new PropertySelectOption('全部', null));
        this.abnormalOptions?.push(new PropertySelectOption('有异常', true));
        this.abnormalOptions?.push(new PropertySelectOption('无异常', false));

        this.metadataService.currencies()
            .subscribe(
                data => {
                    data.forEach(currency => {
                        this.currencyOptions?.push(new PropertySelectOption(currency, currency));
                    })
                },
                error => {
                });
    }

    onSearch() {
        this.proposalSearch.emit(this.search);
    }

    onTimeChange(time: Date[]) {
        const formatter = 'yyyy-MM-dd';
        this.time = time;
        if (time && time.length > 0) {
            this.search.startCreateDate = formatDate(time[0], formatter, 'zh-Hans-CN');
        }
        if (time && time.length > 1) {
            this.search.endCreateDate = formatDate(time[1], formatter, 'zh-Hans-CN');
        }
        if (!time) {
            this.search.startCreateDate = null;
            this.search.endCreateDate = null;
        }
        console.log(this.search);
    }
}
