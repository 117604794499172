import { Injectable, OnInit } from '@angular/core';
import { BaseService } from "../base/base.service";
import { Observable } from "rxjs/internal/Observable";
import { ApiResponse, ExcelReport, PagedResp } from "../api/types";
import { map } from "rxjs/operators";
import { HttpService } from "../api/http.service";
import { HttpParams } from "@angular/common/http";
import { isNullOrUndefined } from "../common/utils";
import { ContractMasterRate, ContractMasterRateSearch } from "./contract-types";
import { HttpParamEncoder } from "../common/encoder/http-param.encoder";

@Injectable({
    providedIn: 'root'
})
export class ContractService extends BaseService implements OnInit {

    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }

    masterRateCreate(masterRate: ContractMasterRate): Observable<boolean> {
        return this.http.post<ApiResponse<boolean>>(this.contractMasterRateUrl + '/create', masterRate, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    masterRateList(search: ContractMasterRateSearch): Observable<PagedResp<ContractMasterRate>> {
        let params = new HttpParams({encoder: new HttpParamEncoder()});        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.name)) {
            params = params.set('name', search.name);
        }
        return this.http.get<ApiResponse<PagedResp<ContractMasterRate>>>(this.contractMasterRateUrl + '/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    masterRateListByProduct(productCode: string): Observable<ContractMasterRate[]> {
        let params = new HttpParams({encoder: new HttpParamEncoder()});        if (!isNullOrUndefined(productCode)) {
            params = params.set('productCode', productCode.toString());
        }
        return this.http.get<ApiResponse<ContractMasterRate[]>>(this.contractMasterRateUrl + '/list-by-product', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    masterRateDelete(id: number): Observable<boolean> {
        return this.http.delete<ApiResponse<boolean>>(this.contractMasterRateUrl + '/' + id, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    masterRateUpdate(masterRate: ContractMasterRate): Observable<boolean> {
        return this.http.put<ApiResponse<boolean>>(this.contractMasterRateUrl + '/update', masterRate, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    masterRateImport(masterRate: ContractMasterRate, formData: FormData): Observable<ExcelReport> {
        return this.http.post<ApiResponse<ExcelReport>>(this.contractMasterRateUrl + '/import/' + masterRate.id, formData, {
            headers: this.getFileHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    masterRateExport(masterRate: ContractMasterRate): Observable<boolean> {
        return this.http.download(this.contractMasterRateUrl + '/export/' + masterRate.id, {
            headers: this.getHeaders(),
            params: null,
            responseType: 'blob',
            observe: 'response'
        }).pipe(map(response => {
            this.http.saveResponseBlob(response);
            return true;
        }))

    }

}
