<ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
<nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">

    <div *ngIf="materials">
        <div style="margin-bottom: 10px">
            <nz-tag>
                <span style="color: #795da3">共{{ materials.length }}个文件</span>
            </nz-tag>
        </div>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap">
            <nz-tag *ngFor="let tag of _tags; let i = index" style="border: #E2EBF6 1px solid; margin-top: 5px"
                    [nzMode]="'checkable'"
                    [nzChecked]="tag.checked"
                    (nzCheckedChange)="onTagCheckChanged($event, tag)">
                <span>{{ tag.tag }}</span>
            </nz-tag>
        </div>
        <div *ngFor="let material of materials; let i = index">
            <div class="material" *ngIf="material.visible">
                <div class="material-file-icon">
                    <span>{{extension(material.filePath)}}</span>
                </div>
                <div class="material-file-elem">
                    <div class="material-file-name">{{material.fileName}}</div>
                    <div class="material-file-path">
                        {{material.filePath}}
                    </div>
                    <div class="material-file-tag">
                        <nz-tag *ngFor="let tag of material.tags; let i = index" [nzMode]="'default'">
                            <span>{{ tag }}</span>
                        </nz-tag>
                    </div>
                </div>
                <div class="material-file-actions">
                    <a *ngIf="canPreview(material)" nz-button nzType="link" nzSize="small" appThrottleClick
                       (throttleClick)="onPreview(material)">
                        <em nz-icon nzType="eye" nzTheme="outline"></em>
                    </a>
                    <a nz-button nzType="link" nzSize="small" appThrottleClick (throttleClick)="onDownload(material)">
                        <em nz-icon nzType="cloud-download" nzTheme="outline"></em>
                    </a>
                    <a nz-button nzType="link" nzSize="small"
                       appThrottleClick
                       (throttleClick)="onShowEditorModal(i, editorTitle, editorContent, editorFooter)">
                        <em nz-icon nzType="edit" nzTheme="outline"></em>
                    </a>
                    <a nz-button nzType="link" nzSize="small" class="delete-button" appThrottleClick
                       (throttleClick)="onDelete(i)">
                        <em nz-icon nzType="delete" nzTheme="outline"></em>
                    </a>
                </div>
            </div>
        </div>

        <div *ngFor="let item of uploader.queue; let i = index">
            <div class="material">
                <nz-progress [nzPercent]="item.progress" nzType="circle" [nzWidth]="48"></nz-progress>
                <div class="material-file-elem">
                    <div class="material-file-name">{{item.file.name}}</div>
                    <div class="material-file-path">
                        <span>{{item._file.path}}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</nz-spin>

<div [class]="highPosition ? 'material-menu high-position' : 'material-menu'">

    <input #fileUpload hidden="hidden" type="file" ng2FileSelect [uploader]="uploader"
           (change)="selectedImportFileOnChanged($event)" multiple/>

    <div style="padding: 10px 5px 10px 5px; background-color: rgba(44,198,229,0.56); border-radius: 5px">
        <label nz-checkbox [(ngModel)]="setTagsWhenUpload" style="margin-right: 15px">上传时是否设置已选标签</label>
    </div>

    <button nz-button nzType="default" nzSize="large" nzShape="circle" appThrottleClick (throttleClick)="upload()"
            style="margin-right: 40px; transform: scale(1.5, 1.5)">
        <i nz-icon nzType="cloud-upload" nzTheme="outline"
           style="color: #07367c; font-size: 20px;"></i>
    </button>
</div>

<ng-template #editorTitle>
    <i nz-icon nzType="edit" nzTheme="outline"
       style="color: #07367c; font-size: 17px; margin-right: 5px"></i><span>編輯</span>
</ng-template>
<ng-template #editorContent let-params>
    <app-property-text [title]="'名稱'" [(value)]="params.file.fileName" [editable]="true"
                       [editing]="true"></app-property-text>
    <app-property-tags [title]="'標籤'" [(value)]="params.file.tags" [editable]="true"
                       [editing]="true"></app-property-tags>
</ng-template>
<ng-template #editorFooter let-ref="modalRef" let-params>
    <button nz-button appThrottleClick (throttleClick)="ref.destroy()">
        <i nz-icon nzType="close" nzTheme="outline"
           style="font-size: 13px;"></i><span>關閉</span>
    </button>
    <button nz-button nzType="primary" appThrottleClick (throttleClick)="onEditorConfirm(ref, params)"
            [nzLoading]="editorLoading">
        <i nz-icon nzType="save" nzTheme="outline"
           style="font-size: 13px;"></i><span>確定</span>
    </button>
</ng-template>
