<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
<!--        <nz-row [nzGutter]="16" *ngIf="proposalStatistic">-->
<!--            <nz-col [nzSpan]="6">-->
<!--                <nz-statistic [nzValue]="(proposalStatistic.total | number)!" [nzTitle]="'计划书总数'"></nz-statistic>-->
<!--            </nz-col>-->
<!--            <nz-col [nzSpan]="6">-->
<!--                <nz-statistic [nzValue]="(proposalStatistic.cleaned | number)!" [nzTitle]="'已清理'"></nz-statistic>-->
<!--            </nz-col>-->
<!--            <nz-col [nzSpan]="6">-->
<!--                <nz-statistic [nzValue]="(proposalStatistic.uncleaned | number)!" [nzTitle]="'未清理'"></nz-statistic>-->
<!--            </nz-col>-->
<!--            <nz-col [nzSpan]="6">-->
<!--                <nz-statistic [nzValue]="(proposalStatistic.abnormal | number)!" [nzTitle]="'异常'"></nz-statistic>-->
<!--            </nz-col>-->
<!--        </nz-row>-->

        <div *ngIf="!knowledges">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="knowledges" style="margin-top: 30px; ">
            <nz-table [nzBordered]="false"
                      [nzOuterBordered]="true"
                      [nzShowPagination]="true"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="knowledges.list"
                      [nzPageIndex]="knowledges.pageNum"
                      [nzPageSize]="knowledges.pageSize"
                      [nzTotal]="knowledges.totalCount"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter"
                      (nzPageIndexChange)="onSearch($event)"
                      [nzScroll]="{ x: '1150px'}">
                <ng-template #tableHeader>
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">知識庫列表
                                <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="刷新" appThrottleClick (throttleClick)="onReload()">
                                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                                </a>
                            </div>
                        </nz-col>
                        <nz-col [nzSpan]="12">
                            <div style="width: 100%; display: flex; justify-content: flex-end;">
                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="添加知識文章" style=" margin-left: 10px;"
                                        appThrottleClick (throttleClick)="onCreate()">
                                    <i nz-icon nzType="plus" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                                        nz-popover [nzPopoverContent]="filterTemplate"
                                        [(nzPopoverVisible)]="filterPopoverVisible"
                                        nzPopoverTrigger="click"
                                        nzPopoverPlacement="bottomRight"
                                        nz-tooltip nzTooltipTitle="知识库搜索"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="search" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140; font-weight: bold">{{ knowledges.totalCount }}</span> 篇知識
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th nzLeft nzWidth="60px"><span nz-tooltip nzTooltipTitle="編號">編號</span></th>
                    <th><span nz-tooltip nzTooltipTitle="標題">標題</span></th>
                    <th><span nz-tooltip nzTooltipTitle="類型">類型</span></th>
                    <th><span nz-tooltip nzTooltipTitle="標籤">標籤</span></th>
                    <th><span nz-tooltip nzTooltipTitle="總查看次數">總查看次數</span></th>
                    <th><span nz-tooltip nzTooltipTitle="分享次数">分享次数</span></th>
                    <th><span nz-tooltip nzTooltipTitle="發布時間">發布時間</span></th>
                    <th nzRight nzWidth="160px" style="text-align: center"><span nz-tooltip nzTooltipTitle="操作">操作</span></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let knowledge of knowledges.list; let i = index" appThrottleClick (throttleClick)="onKnowledgeInfo(knowledge)">
                    <td nzLeft>
                        <span >
                            {{ knowledge.id }}
                        </span>
                    </td>
                    <td nz-tooltip [nzTooltipTitle]="knowledge.title">{{ knowledge.title }}</td>
                    <td nz-tooltip [nzTooltipTitle]="knowledge.typeName" style="font-size: 12px; color: #6E84A3">
                        <span *ngIf="knowledge.typeId">
                            通用知識 - {{ knowledge.typeName || 'N/A' }}
                        </span>
                        <span *ngIf="!knowledge.typeId">
                            熱門產品 - {{ knowledge.productName || 'N/A' }}
                        </span>
                    </td>
                    <td >
                        <nz-tag *ngFor="let tag of knowledge.tags; let i = index" [nzMode]="'default'">
                            <span style="font-size: 12px; padding: 2px;">{{ tag }}</span>
                        </nz-tag>
                    </td>
                    <td style="font-size: 13px">{{ knowledge.visits }}</td>
                    <td style="font-size: 11px">{{ knowledge.shares}}</td>
                    <td style="font-size: 11px">{{ knowledge.issueDate | date:'yyyy-MM-dd' }}</td>
                    <td nzRight>
                        <div class="operations">
                            <nz-switch nzSize="small" [(ngModel)]="knowledge.isTop"
                                       nz-tooltip nzTooltipTitle="是否置顶"
                                       style="line-height: 14px;padding-right: 7px;"
                                       appThrottleClick (throttleClick)="isTopChange(knowledge);$event.stopPropagation();">
                            </nz-switch>

                            <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="公共链接" appThrottleClick (throttleClick)="onNavigate(knowledge);$event.stopPropagation();">
                                <em nz-icon nzType="link" nzTheme="outline"></em>
                            </a>
                            <a *ngIf="!knowledge.notified" nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="发送通知" appThrottleClick (throttleClick)="onNotify(knowledge);$event.stopPropagation();">
                                <em nz-icon nzType="send" nzTheme="outline"></em>
                            </a>
                            <a nz-button nzType="link" nzSize="small" class="delete-button" nz-tooltip nzTooltipTitle="刪除" appThrottleClick (throttleClick)="onDelete(knowledge);$event.stopPropagation();">
                                <em nz-icon nzType="delete" nzTheme="outline"></em>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>

        </div>

    </nz-spin>

</div>

<!-- 气泡Template -->
<ng-template #filterTemplate>
    <div style="width: 400px; padding: 5px;">
        <div style="padding: 0 0 15px 0">{{'搜索'}}</div>
        <div class="property-bottom-line-blue"></div>
        <!-- 名字 -->
        <app-property-text-edit [title]="'关键词'" [(value)]="search.keyword"></app-property-text-edit>

        <!-- 公司 -->
        <app-property-select-edit [title]="'保險公司'" [(value)]="search.companyCode" [showSearch]="true"
                                  [options]="companyOptions"></app-property-select-edit>

        <!-- 类型 -->
        <app-property-select-edit [title]="'类型'" [(value)]="search.typeId" [showSearch]="true"
                                  [options]="typeOptions"></app-property-select-edit>

        <nz-row [nzGutter]="5">
            <nz-col nzSpan="12">
                <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
                    <span>{{'重置' | translate}}</span>
                </button>
            </nz-col>
            <nz-col nzSpan="12">
                <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
                    <span>{{'确定' | translate}}</span>
                </button>
            </nz-col>
        </nz-row>
    </div>
</ng-template>
