<div class="profile-large-title">
<!--    <i nz-icon nzType="plus" nzTheme="outline" style="color: #07367c; font-size: 25px;"></i>-->
    <span style="font-weight: bold; width: 100%; font-size: 28px;">添加產品</span>
</div>

<div style="margin-top: 50px; padding-bottom: 60px; padding-left: 10px; padding-right: 10px">

    <app-property-text [title]="'產品码（唯一，大寫字母A-Z，數字0-9和:_()符號） * '" [(value)]="product.code" [editable]="true" [editing]="true"></app-property-text>

    <app-property-text [title]="'產品默認名稱'" [(value)]="product.name" [editable]="true" [editing]="true" [required]="true"></app-property-text>

    <app-property-i18n [title]="'產品名稱（多語言配置）'" [required]="true" [(value)]="product.nameI18n" [editable]="true"
                       [editing]="true"></app-property-i18n>

    <app-property-i18n [title]="'產品簡稱（多語言配置）'" [(value)]="product.shortNameI18n" [editable]="true"
                       [editing]="true"></app-property-i18n>

    <app-property-i18n [title]="'產品合規名稱（多語言配置）'" [(value)]="product.complianceNameI18n" [editable]="true"
                       [editing]="true"></app-property-i18n>

    <app-property-select [title]="'產品性質'" [required]="true" [name]="product.commonType | productCommonType" [(value)]="product.commonType"
                         [editable]="true" [editing]="true" [options]="commonTypeOptions"></app-property-select>

    <app-property-select [title]="'類型'" [required]="true" [name]="product.type | productType" [(value)]="product.type"
                         [editable]="true" [editing]="true" [options]="typeOptions"></app-property-select>

    <app-property-select [title]="'保險險種'" [required]="true" [(value)]="product.categoryCode"
                         [editable]="true" [editing]="true"
                         [options]="categoryOptions"></app-property-select>

    <app-property-select [title]="'保險公司'" [required]="true" [(value)]="product.companyCode"
                         [editable]="true" [editing]="true" [options]="companyOptions"></app-property-select>

    <app-property-select [title]="'狀態'" [required]="true" [name]="product.status | productStatus" [(value)]="product.status"
                         [editable]="true" [editing]="true" [options]="statusOptions"></app-property-select>

    <app-property-switch [title]="'是否標準產品'" [(value)]="product.standard" [editing]="true" [editable]="true">
    </app-property-switch>

    <app-property-tags [title]="'產品年期'" [required]="true" [(value)]="product.premiumTerms" [editable]="true"
                       [editing]="true"></app-property-tags>

    <app-property-select [title]="'貨幣'" [editable]="true" [editing]="true" [name]="product.currencies" [(value)]="product.currencies" [mode]="'multiple'" [options]="currencyOptions"></app-property-select>

    <app-property-select [title]="'繳費頻率'" [editable]="true" [editing]="true" [name]="frequencyNames" [(value)]="product.frequencies" [mode]="'multiple'" [options]="frequencyOptions"></app-property-select>

    <app-property-text [title]="'保障年期'" [(value)]="product.policyPeriod" [editable]="true" [editing]="true"></app-property-text>

    <app-property-switch [title]="'是否可對比'" [(value)]="product.comparable" [editing]="true" [editable]="true">
    </app-property-switch>

    <app-property-switch [title]="'是否可試算'" [(value)]="product.quotable" [editing]="true" [editable]="true">
    </app-property-switch>

    <app-property-select *ngIf="product.quotable" [title]="'保費試算公式'" [name]="product.quotationFormula | productQuotationFormula" [(value)]="product.quotationFormula"
                         [editable]="true" [editing]="true" [options]="quotationFormulaOptions"></app-property-select>

    <app-property-switch [title]="'是否適用提取計劃'" [(value)]="product.withdrawalable" [editing]="true"
                         [editable]="true"></app-property-switch>

    <app-property-switch [title]="'是否支持計劃書機器人'" [(value)]="product.proposalRPASupported" [editing]="true"
                         [editable]="true"></app-property-switch>

    <app-property-switch [title]="'是否支持製作規劃書'" [(value)]="product.financialPlanSupported" [editing]="true"
                         [editable]="true"></app-property-switch>

    <app-property-datetime [title]="'上市日期'" [(value)]="product.launchDate" [editable]="true" [editing]="true" format="yyyy-MM-dd"></app-property-datetime>

    <app-property-datetime [title]="'停售日期'" [(value)]="product.discontinuationDate" [editable]="true" [editing]="true" format="yyyy-MM-dd"></app-property-datetime>

    <app-property-text [title]="'產品排序（數值越小排名越前）'" [(value)]="product.rank" [editable]="true"
                       [editing]="true"></app-property-text>

    <app-property-text [title]="'產品描述'" [(value)]="product.desc" [editing]="true" [editable]="true"
                       [type]="dataService.PropertyTextEditType.TEXT_AREA"></app-property-text>

    <app-property-text [title]="'内部备注'" [(value)]="product.internalRemark" [editing]="true" [editable]="true"
                       [type]="dataService.PropertyTextEditType.TEXT_AREA"></app-property-text>

    <div class="footer">
        <div nz-row style="width: 100%; height: 100%;">
            <div nz-col nzSpan="24" class="footer-button-primary" appThrottleClick (throttleClick)="onCreate()">
                <a nz-button nzType="link" nzBlock nzSize="large">
                    <em nz-icon nzType="save" nzTheme="outline"></em>
                    <span>創 建</span>
                </a>
            </div>
        </div>
    </div>

</div>
