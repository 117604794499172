<div style="padding-bottom: 60px">
    <div class="search-title">
        <i nz-icon nzType="search" nzTheme="outline" style="color: #07367c; font-size: 20px;"></i>
        <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 20px;">計劃書搜索</span>
    </div>

    <app-property-text-edit [title]="'ID'" [(value)]="search.id"></app-property-text-edit>
    <app-property-text-edit [title]="'客户'" [(value)]="search.instance"></app-property-text-edit>
    <app-property-text-edit [title]="'计划书编号'" [(value)]="search.proposalId"></app-property-text-edit>
    <app-property-text-edit [title]="'公司碼'" [(value)]="search.companyCode"></app-property-text-edit>
    <app-property-text-edit [title]="'產品碼'" [(value)]="search.productCode"></app-property-text-edit>
    <app-property-text-edit [title]="'產品名稱'" [(value)]="search.productName"></app-property-text-edit>
    <app-property-text-edit [title]="'年期'" [(value)]="search.premiumTerm"></app-property-text-edit>
    <app-property-text-edit [title]="'年龄'" [(value)]="search.age"></app-property-text-edit>
    <app-property-select [title]="'性別'" [editable]="true" [editing]="true" [(value)]="search.gender" [options]="genderOptions"></app-property-select>
    <app-property-select [title]="'是否吸煙'" [editable]="true" [editing]="true" [(value)]="search.smokeCondition" [options]="smokeOptions"></app-property-select>
    <app-property-select [title]="'貨幣'" [editable]="true" [editing]="true" [(value)]="search.currency" [options]="currencyOptions"></app-property-select>
    <app-property-select-edit [title]="'已清理'" [(value)]="search.cleaned" [options]="cleanedOptions"></app-property-select-edit>
    <app-property-select-edit [title]="'是否异常'" [(value)]="search.abnormal" [options]="abnormalOptions"></app-property-select-edit>
    <app-property-rangetime [title]="'創建時間'" [(value)]="time" [editing]="true" (valueChange)="onTimeChange($event)"
                            [editable]="true"></app-property-rangetime>

    <!--    <app-property-text-edit [title]="'產品名称'" [(value)]="search.name"></app-property-text-edit>-->

<!--    <app-property-select-edit [title]="'類型'" [(value)]="search.type" [options]="typeOptions"></app-property-select-edit>-->

<!--    <app-property-select-edit [title]="'保險險種'" [(value)]="search.categoryCode"-->
<!--                              [options]="categoryOptions"></app-property-select-edit>-->

<!--    <app-property-select-edit [title]="'保險公司'" [(value)]="search.companyCode"-->
<!--                              [options]="companyOptions"></app-property-select-edit>-->

<!--    <app-property-select-edit [title]="'状态'" [(value)]="search.status"-->
<!--                              [options]="statusOptions"></app-property-select-edit>-->

<!--    <app-property-select-edit [title]="'是否标准产品'" [(value)]="search.standard"-->
<!--                              [options]="standardOptions"></app-property-select-edit>-->

<!--    <app-property-select-edit [title]="'是否热门产品'" [(value)]="search.hot"-->
<!--                              [options]="hotOptions"></app-property-select-edit>-->

<!--    <app-property-select-edit [title]="'是否可對比'" [(value)]="search.comparable"-->
<!--                              [options]="comparableOptions"></app-property-select-edit>-->

<!--    <app-property-select-edit [title]="'是否可试算'" [(value)]="search.quotable"-->
<!--                              [options]="quotableOptions"></app-property-select-edit>-->

<!--    <app-property-select-edit [title]="'是否适用提取计划'" [(value)]="search.withdrawalable"-->
<!--                              [options]="withdrawalableOptions"></app-property-select-edit>-->

<!--    <app-property-select-edit [title]="'是否支持计划书机器人'" [(value)]="search.proposalRPASupported"-->
<!--                              [options]="proposalRPASupportedOptions"></app-property-select-edit>-->

<!--    <app-property-select-edit [title]="'是否支持规划书制作'" [(value)]="search.financialPlanSupported"-->
<!--                              [options]="financialPlanSupportedOptions"></app-property-select-edit>-->

    <div class="footer">
        <div nz-row style="width: 100%; height: 100%;">
            <div nz-col nzSpan="24" class="footer-button-primary" appThrottleClick (throttleClick)="onSearch()">
                <a nz-button nzType="link" nzBlock nzSize="large">
                    <em nz-icon nzType="search" nzTheme="outline"></em>
                    <span>搜 索</span>
                </a>
            </div>
        </div>
    </div>
</div>
