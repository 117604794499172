<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
        <nz-row [nzGutter]="16" *ngIf="proposalStatistic">
            <nz-col [nzSpan]="6">
                <nz-statistic [nzValue]="(proposalStatistic.total | number)!" [nzTitle]="'计划书总数'"></nz-statistic>
            </nz-col>
            <nz-col [nzSpan]="6">
                <nz-statistic [nzValue]="(proposalStatistic.cleaned | number)!" [nzTitle]="'已清理'"></nz-statistic>
            </nz-col>
            <nz-col [nzSpan]="6">
                <nz-statistic [nzValue]="(proposalStatistic.uncleaned | number)!" [nzTitle]="'未清理'"></nz-statistic>
            </nz-col>
            <nz-col [nzSpan]="6">
                <nz-statistic [nzValue]="(proposalStatistic.abnormal | number)!" [nzTitle]="'异常'"></nz-statistic>
            </nz-col>
        </nz-row>

        <div *ngIf="!proposals">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="proposals" style="margin-top: 30px; ">
            <nz-table [nzBordered]="false"
                      [nzOuterBordered]="true"
                      [nzShowPagination]="false"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="proposals.list"
                      [nzPageIndex]="proposals.pageNum"
                      [nzPageSize]="proposals.pageSize"
                      [nzTotal]="proposals.totalCount"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter"
                      nzTableLayout="fixed"
                      (nzPageIndexChange)="onSearch($event)"
                      [nzScroll]="{ x: '2150px'}">
                <ng-template #tableHeader>
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">計劃書列表
                                <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="刷新"
                                   appThrottleClick (throttleClick)="onReload()">
                                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                                </a>
                            </div>
                        </nz-col>
                        <nz-col [nzSpan]="12">
                            <div style="width: 100%; display: flex; justify-content: flex-end;">

                                <input #fileUpload hidden="hidden" type="file" ng2FileSelect [uploader]="uploader"
                                       (change)="selectedImportFileOnChanged($event)"/>

<!--                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip-->
<!--                                        nzTooltipTitle="同步RPA计划书机器人产品列表"-->
<!--                                        appThrottleClick (throttleClick)="rpaProductSync()"-->
<!--                                        style=" margin-left: 10px;">-->
<!--                                    <i nz-icon nzType="sync" nzTheme="outline"-->
<!--                                       style="color: #07367c; font-size: 20px;"></i>-->
<!--                                </button>-->

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="計劃書搜索"
                                        appThrottleClick (throttleClick)="openSearchDrawer()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="search" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140;">{{ proposals.totalCount }}</span> 份計劃書
                    </div>
                    <div style="text-align: right;margin-top: 6px;">
                        <nz-pagination [(nzPageIndex)]="proposals.pageNum"
                                       [nzPageSize]="proposals.pageSize"
                                       [nzTotal]="proposals.totalCount"
                                       nzShowSizeChanger nzShowQuickJumper
                                       (nzPageIndexChange)="onSearch($event)"
                                       (nzPageSizeChange)="onPageSize($event)">
                        </nz-pagination>
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th nzLeft nzWidth="165px"><span>ID</span></th>
                    <th ><span>客户</span></th>
                    <th nzWidth="165px"><span>计划书编号</span></th>
                    <th nzWidth="200px"><span>產品名稱</span></th>
                    <th nzWidth="100px"><span>產品年期</span></th>
                    <th nzWidth="100px"><span>付款頻率</span></th>
                    <th nzWidth="120px"><span>國家地區</span></th>
                    <th nzWidth="90px"><span>性別</span></th>
                    <th nzWidth="60px"><span>年齡</span></th>
                    <th nzWidth="80px"><span>貨幣</span></th>
                    <th><span>保费</span></th>
                    <th><span>保额</span></th>
                    <th nzWidth="100px"><span>吸煙狀況</span></th>
                    <th><span>已清理</span></th>
                    <th><span>异常</span></th>
                    <th><span>備註</span></th>
                    <th nzWidth="90px"><span>附加需求</span></th>
                    <th><span>创建时间</span></th>
                    <th nzRight nzWidth="200px"><span nz-tooltip nzTooltipTitle="預覽|下載">操作</span></th>
                    <!--<th style="text-align: center">操作</th>-->
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let proposal of proposals.list; let i = index" appThrottleClick
                    (throttleClick)="onPreview(proposal)">
                    <td nzLeft>
                        <span
                            [ngStyle]="{'color':proposal.url.objectName ? '#002d6a' : '#fc3535', 'font-size' :'12px', 'font-weight' :'bold'}">
                            {{ proposal.id }}
                        </span>
                    </td>
                    <td>{{ proposal.instance }}</td>
                    <td>{{ proposal.proposalId }}</td>
                    <td nz-tooltip [nzTooltipTitle]="proposal.productName">{{ proposal.productName }}</td>
                    <td>{{ proposal.premiumTerm }}</td>
                    <td>{{ proposal.frequency || 'N/A' }}</td>
                    <td>{{ proposal.region }}</td>
                    <td>{{ proposal.gender }}</td>
                    <td>{{ proposal.age }}</td>
                    <td>{{ proposal.currency }}</td>
                    <td>{{ proposal.premium || 'N/A' }}</td>
                    <td>{{ proposal.sumInsured || 'N/A' }}</td>
                    <td>{{ proposal.smokeCondition }}</td>
                    <td>
                        <nz-switch [(ngModel)]="proposal.cleaned" [nzControl]="true" appThrottleClick
                                   (throttleClick)="onSwitchCleaned(proposal)"
                                   [nzLoading]="proposal.loading" nzSize="small">
                        </nz-switch>
                    </td>
                    <td>
                        <nz-switch [(ngModel)]="proposal.abnormal" [nzControl]="true"
                                   appThrottleClick (throttleClick)="onSwitchAbnormal(proposal)"
                                   [nzLoading]="proposal.loading" nzSize="small">
                        </nz-switch>
                    </td>
                    <td><span style="font-size: 11px" nz-tooltip
                              [nzTooltipTitle]="proposal.remark">{{proposal.remark || 'N/A'}}</span></td>
                    <td nzEllipsis>{{proposal.special}}</td>
                    <td><span style="font-size: 11px">{{ proposal.createTime | date:'yyyy-MM-dd HH:mm:ss' }}</span></td>
                    <td nzRight>
                        <div>
                            <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="詳情">
                                <em nz-icon nzType="eye" nzTheme="outline"></em>
                            </a>
                            <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="下載"
                               appThrottleClick (throttleClick)="onDownload(proposal)">
                                <em nz-icon nzType="cloud-download" nzTheme="outline"></em>
                            </a>
                            <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="清理计划书"
                               appThrottleClick (throttleClick)="onClean(proposal)">
                                <em nz-icon nzType="clear" nzTheme="outline"></em>
                            </a>
                            <a *ngIf="proposal.financialPlanSupported" nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="PDF提取"
                               appThrottleClick (throttleClick)="onExtract(proposal)">
                                <em nz-icon nzType="build" nzTheme="outline"></em>
                            </a>
                            <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="備註"
                               appThrottleClick
                               (throttleClick)="onShowRemarkModal(i, remarkTitle, remarkContent, remarkFooter)">
                                <em nz-icon nzType="message" nzTheme="outline"></em>
                            </a>
                            <!--                            <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="設置為已清理" appThrottleClick (throttleClick)="setCleaned(proposal)">-->
                            <!--                                <em style="color: #0077aa" nz-icon nzType="check" nzTheme="outline"></em>-->
                            <!--                            </a>-->
                            <!--                            <a nz-button nzType="link" nzSize="small"  class="delete-button" appThrottleClick (throttleClick)="onDelete(i)">-->
                            <!--                                <em nz-icon nzType="delete" nzTheme="outline"></em>-->
                            <!--                            </a>-->
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>

        </div>

    </nz-spin>

</div>

<!-- profile drawer -->
<nz-drawer [nzVisible]="showPreviewDrawer" [nzMask]="true" [nzWidth]="1200" [nzClosable]="true"
           [nzTitle]="proposalInfo.proposalId"
           (nzOnClose)="closePreviewDrawer()">
    <ng-container *nzDrawerContent>
        <div style="position: relative">
            <div style="position: absolute; width: 360px">
                <div class="section-title">
                    <span style="margin-left: 8px">信息</span>
                </div>
                <nz-row [nzGutter]="15">
                    <nz-col [nzSpan]="8">
                        <div class="title-col">客戶姓名</div>
                        <div>{{proposalInfo.name || 'N/A'}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">性別</div>
                        <div>{{proposalInfo.gender}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">年齡</div>
                        <div>{{proposalInfo.age}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">吸烟</div>
                        <div>{{proposalInfo.smokeCondition}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">計劃書語言</div>
                        <div>{{proposalInfo.language}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">公司</div>
                        <div>{{proposalInfo.companyCode}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">产品</div>
                        <div>{{proposalInfo.productName || proposalInfo.productCode}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">供款年期</div>
                        <div>{{proposalInfo.premiumTerm}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">貨幣</div>
                        <div>{{proposalInfo.currency}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">保額</div>
                        <div>{{proposalInfo.sumInsured}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">保費</div>
                        <div>{{proposalInfo.premium}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">繳費頻率</div>
                        <div>{{proposalInfo.frequency || 'N/A'}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">地區</div>
                        <div>{{proposalInfo.region}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">製作方式</div>
                        <div *ngIf="proposalInfo.processingMode === 'ROBOT'">RPA機器人</div>
                        <div *ngIf="proposalInfo.processingMode === 'MANUAL'">人工</div>
                        <div *ngIf="!proposalInfo.processingMode">N/A</div>
                    </nz-col>
                    <nz-col [nzSpan]="24">
                        <div class="title-col">附加需求</div>
                        <div>{{proposalInfo.special || 'N/A'}}</div>
                    </nz-col>
                </nz-row>
                <div class="section-title" style="margin-top: 10px">
                    <span style="margin-left: 8px">醫療選項信息</span>
                </div>
                <nz-row *ngIf="proposalInfo.medicalOption" [nzGutter]="15">
                    <nz-col [nzSpan]="8">
                        <div class="title-col">保障區域</div>
                        <div>{{proposalInfo.medicalOption.region || 'N/A'}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">自付額</div>
                        <div>{{proposalInfo.medicalOption.deductible || 'N/A'}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">病房等級</div>
                        <div>{{proposalInfo.medicalOption.level || 'N/A'}}</div>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="title-col">附加保障</div>
                        <div>{{proposalInfo.medicalOption.supplementaryBenefits && proposalInfo.medicalOption.supplementaryBenefits.length > 0 ? proposalInfo.medicalOption.supplementaryBenefits.join() : 'N/A'}}</div>
                    </nz-col>
                </nz-row>
                <div class="section-title" style="margin-top: 10px">
                    <span style="margin-left: 8px">現金提取計劃</span>
                </div>
                <div *ngIf="proposalInfo.withdrawals && proposalInfo.withdrawals.length">
                    <nz-row *ngFor="let withdrawal of proposalInfo.withdrawals;let i = index" [nzGutter]="15">
                        <nz-col [nzSpan]="24">
                            <div style="color: #AAAAAA; font-size: 28px; margin: 0 15px 0 0">
                                {{(i + 1) | number: '2.0'}}
                            </div>
                        </nz-col>
                        <nz-col [nzSpan]="8">
                            <div class="title-col">提取计划名</div>
                            <div>{{withdrawal.name || 'N/A'}}</div>
                        </nz-col>
                        <nz-col [nzSpan]="8">
                            <div class="title-col">从哪一保单年度</div>
                            <div>{{withdrawal.from}}</div>
                        </nz-col>
                        <nz-col [nzSpan]="8">
                            <div class="title-col">至哪一保单年度</div>
                            <div>{{withdrawal.to}}</div>
                        </nz-col>
                        <nz-col [nzSpan]="8">
                            <div class="title-col">金额</div>
                            <div>{{withdrawal.amount}}</div>
                        </nz-col>
                        <nz-col [nzSpan]="8">
                            <div class="title-col">是否最大金额</div>
                            <div>{{withdrawal.max ? '是' : '否'}}</div>
                        </nz-col>
                    </nz-row>
                </div>
                <div>
                    <div class="title-col">是否允許減少基本金額</div>
                    <div>{{proposalInfo.reduceBasic ? '是' : '否'}}</div>
                </div>
                <div class="section-title" style="margin-top: 10px">
                    <span style="margin-left: 8px">附加险</span>
                </div>
                <div *ngIf="proposalInfo.riders && proposalInfo.riders.length">
                    <nz-row *ngFor="let rider of proposalInfo.riders;let i = index" [nzGutter]="15">
                        <nz-col [nzSpan]="24">
                            <div style="color: #AAAAAA; font-size: 28px; margin: 0 15px 0 0">
                                {{(i + 1) | number: '2.0'}}
                            </div>
                        </nz-col>
                        <nz-col [nzSpan]="8">
                            <div class="title-col">产品码</div>
                            <div>{{rider.productCode || 'N/A'}}</div>
                        </nz-col>
                        <nz-col [nzSpan]="8">
                            <div class="title-col">保额</div>
                            <div>{{rider.sumAssured}}</div>
                        </nz-col>
                        <nz-col [nzSpan]="8">
                            <div class="title-col">保费</div>
                            <div>{{rider.premium}}</div>
                        </nz-col>
                        <nz-col *ngIf="rider.medicalPlan" [nzSpan]="8">
                            <div class="title-col">醫療險保障區域</div>
                            <div>{{rider.medicalPlan.region}}</div>
                        </nz-col>
                        <nz-col *ngIf="rider.medicalPlan" [nzSpan]="8">
                            <div class="title-col">醫療險等級</div>
                            <div>{{rider.medicalPlan.medicalLevel}}</div>
                        </nz-col>
                        <nz-col *ngIf="rider.medicalPlan" [nzSpan]="8">
                            <div class="title-col">醫療險等級</div>
                            <div>{{rider.medicalPlan.supplementaryBenefits && rider.medicalPlan.supplementaryBenefits.length > 0 ? rider.medicalPlan.supplementaryBenefits.join() : 'N/A'}}</div>
                        </nz-col>
                    </nz-row>
                </div>
            </div>
            <div style="position: absolute; right: 0; top: 0; width: 800px;">
                <pdf-viewer *ngIf="previewUrl" [src]="previewUrl"
                            [fit-to-page]="true"
                            [show-all]="true"
                            [original-size]="false"
                            [zoom-scale]="'page-width'"
                            [stick-to-page]="true"
                            style="display: block;"></pdf-viewer>
            </div>
        </div>
    </ng-container>
</nz-drawer>

<nz-drawer [nzVisible]="showSearchDrawer" [nzMask]="true" [nzWidth]="520" [nzClosable]="true"
           (nzOnClose)="closeSearchDrawer()">
    <ng-container *nzDrawerContent>
        <app-proposal-search #search (proposalSearch)="onProposalSearch($event)"></app-proposal-search>
    </ng-container>
</nz-drawer>

<ng-template #remarkTitle>
    <i nz-icon nzType="message" nzTheme="outline"
       style="color: #07367c; font-size: 17px; margin-right: 5px"></i><span>備註</span>
</ng-template>
<ng-template #remarkContent let-params>
    <input type="text" class="remark-input" style="width: 100%;" [(ngModel)]="params.remark" placeholder=""/>
</ng-template>
<ng-template #remarkFooter let-ref="modalRef" let-params>
    <button nz-button appThrottleClick (throttleClick)="ref.destroy()">
        <i nz-icon nzType="close" nzTheme="outline"
           style="font-size: 13px;"></i><span>關閉</span>
    </button>
    <button nz-button nzType="primary" appThrottleClick (throttleClick)="onRemarkConfirm(ref, params)"
            [nzLoading]="remarkLoading">
        <i nz-icon nzType="save" nzTheme="outline"
           style="font-size: 13px;"></i><span>確定</span>
    </button>
</ng-template>
