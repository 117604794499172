import {Environment} from "./environment";
import {ProductCompany, ProductCompanyRegion} from "../product/product-types";
import {plainToInstance, Transform} from "class-transformer";
import {plainToArray} from "../common/util/class-transform";

export class Model {
    id: number;
    createTime: number;
    updateTime: number;
}

export class Error {
    err: string;
    code: number;
    msg: string;
}

export class ApiResponse<T> {
    code: number;
    err: string;
    msg: string;
    detail: string;
    param: string;
    data: T;
}

export class PagedReq {
    pageNum: number = 1;
    pageSize: number = 10;
}

export class PagedResp<T> {
    pageNum: number;
    pageSize: number;
    totalCount: number;
    totalPage: number
    list: [T];
}

export class I18n {
    zhHansCN: string;
    zhHantHK: string;
    enUS: string;
}

export enum ProcessLogType {
    INFO = "INFO",
    WARN = "WARN",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

export class ProcessLog {
    type: ProcessLogType;
    title: string;
    content: string;
    datetime: number;
}

export class Operator extends Model {
    account: string;
    nickname: string;
    token: string;
    xiumiOpenId: string; // 秀米OpenId
    authorities: string[];
}

export class LoginByEmailRequest {
    email: string;
    password: string;
}

export class LoginByAccountRequest {
    account: string;
    password: string;

}

export class Account {
    env: Environment;
    account: string;
    password: string;

    get key() {
        return this.env + ':' + this.account;
    }
}

export class Instance extends Model {
    secret: string;
    name: string;
    remark: string;
    checked: boolean;
}

export class InstanceProductSync {
    productCodes: string[];
    instances: string[];
}

export class InstanceProductDelete {
    productCodes: string[];
    instances: string[];
}

export class InstanceCompanyDelete {
    companyCodes: string[];
    instances: string[];
}

export class InstanceCompanySync {
    productCompanyCodes: string[];
    instances: string[];
}

export class InstanceInformationSync {
    informationIds: number[];
    instances: string[];
}

export class InstanceInformationDelete {
    informationIds: number[];
    instances: string[];
}

export enum ProposalLanguage {
    CN = "CNY",
    HK = "HK",
    EN = "EN"
}

export enum Currency {
    CNY = "CNY",
    HKD = "HKD",
    MOP = "MOP",
    USD = "USD",
    EUR = "EUR",
    JPY = "JPY",
    CAD = "CAD",
    GBP = "GBP",
    AUD = "AUD",
    SGD = "SGD",
    CHF = "CHF",
    NZD = "NZD",
}

/// 服務提供商
export enum MaterialProvider {
    AZURE = "AZURE",
    ALI = "ALI",
    MINIO = "MINIO",
}

/// 訪問控制
export enum MaterialAccessControl {
    PRIVATE = "PRIVATE", // 私有
    PUBLIC_READ = "PUBLIC_READ", // 公共讀
    PUBLIC_READWRITE = "PUBLIC_READWRITE", // 公共讀寫
}

export class Material {
    provider: MaterialProvider;
    bucket: string;
    dir: string;
    fileName: string;
    objectName: string;
    contentType: string;
    uploadTime: number;
    filePath: string;
    accessControl: MaterialAccessControl;
    author: string;
    tags: string[];

    visible: boolean = true;
}

export enum MaterialUploadTo {
    PRODUCT = 'PRODUCT', // 保險產品
    COMPANY = 'COMPANY', // 保險公司
    INFORMATION = "INFORMATION", // 资讯
    KNOWLEDGE = "KNOWLEDGE", // 知识库
    CONTRACT = "CONTRACT", // 合约
    COMMON = 'COMMON', // 公共
    TICKET = 'TICKET', // 工单
}

export class MaterialUploadFileReq {
    uploadTo: MaterialUploadTo;
    key: string;
}

export class MaterialUploadTokenReq {
    uploadTo: MaterialUploadTo;
    /**
     * 模块实体对应ID
     * 如果是PRODUCT，则为Product Id
     * 如果是INFORMATION，则为Information Id
     * 依此类推...
     * 如果没有Id，则uploadTo设置为COMMON，key设置为空
     */
    key: string;
    fileName: string;
}

export class MaterialUploadToken {
    accessid: string;
    policy: string;
    signature: string;
    host: string;
    expire: string;
    material: Material;
}

export class MaterialAccessTokenReq {
    bucket: string;
    objectName: string;
}

export class MaterialAccessToken {
    url: string;
}

export class MaterialUploadReq {
    uploadTo: MaterialUploadTo;
    key: string;

    static fromProduct(product: Product): MaterialUploadReq {
        const req = new MaterialUploadReq();
        req.uploadTo = MaterialUploadTo.PRODUCT;
        req.key = product.code;
        return req;
    }

    static fromCompany(company: ProductCompany): MaterialUploadReq {
        const req = new MaterialUploadReq();
        req.uploadTo = MaterialUploadTo.COMPANY;
        req.key = company.code;
        return req;
    }

    static fromInformation(information: Information): MaterialUploadReq {
        const req = new MaterialUploadReq();
        req.key = information.id?.toString();
        req.uploadTo = MaterialUploadTo.INFORMATION;
        return req;
    }

    static fromKnowledge(knowledge: Knowledge): MaterialUploadReq {
        const req = new MaterialUploadReq();
        req.key = knowledge.id?.toString();
        req.uploadTo = MaterialUploadTo.KNOWLEDGE;
        return req;
    }

    static fromTicket(id: number): MaterialUploadReq {
        const req = new MaterialUploadReq();
        req.key = id?.toString();
        req.uploadTo = MaterialUploadTo.TICKET;
        return req;
    }
}

export class MaterialModifyReq {
    index: number;
    file: Material;
}

export class MaterialFilterReq {
    tags: string[] = [];
    files: Material[] = [];
}

export class MaterialFilterRes {
    files: Material[];
}

export enum AgeFormula {
    NEXT_BIRTHDAY = "NEXT_BIRTHDAY",                   // 下一個生日
    LAST_BIRTHDAY = "LAST_BIRTHDAY",                  // 上一個生日
    NEAREST_BIRTHDAY = "NEAREST_BIRTHDAY",            // 最近的生日
    CURRENT_BIRTHDAY = "CURRENT_BIRTHDAY",            // 週歲
}

export enum ProposalAmountType {
    BOTH = "BOTH",                  // 两者都可
    SUM_ASSURED = "SUM_ASSURED",    // 保额
    PREMIUM = "PREMIUM",            // 保费
}

export class Link {
    link: string;                       // 链接地址
    description: string;                // 链接说明
    descriptionI18n: I18n = new I18n();
    rank: number;
}

export class CreditRating {
    rating: string;
    project: string;
    ratingAgency: string;
    time: number;
}

export class ProductFeature {
    highlights: I18n[]; // 产品特色
    maxWithdrawal: boolean; // 支持最大提取
    hedgeable: boolean; // 支持作为对冲产品
    hedgeProducts: string[]; // 支持对冲的产品列表
    proposalAmountType: ProposalAmountType; // 计划书可选金额类型
    @Transform((value) => plainToInstance(I18n, Object.assign(new I18n(), value.value)))
    proposalAmountTip: I18n; // 计划书金额提示语
}

/**
 * 產品統計數據
 */
export class ProductStatistic {
    productNumber: number;        // 唯一編碼
    companyNumber: number;        // 公司名稱
    categoryNumber: number;       // 公司名稱
}

/**
 * 公司列表項實體
 */
export class ProductCategory {
    code: string;           // 唯一編碼
    name: string;           // 類別名稱
    nameI18n: I18n;         // 類別名稱
    desc: string;           // 類別簡介
    logo: string;           // 類別圖標
    rank: number;           // 類別排序
}

export enum ProductCategoryCode {
    SAVINGS = "SAVINGS",                                     // 儲蓄保險
    CRITICAL_ILLNESS = "CRITICAL_ILLNESS",                  // 危疾保險
    MEDICAL = "MEDICAL",                                    // 醫療保險
    ACCIDENT = "ACCIDENT",                                  // 意外保險
    LIFE = "LIFE",                                          // 人壽保險
    UNIVERSAL_LIFE = "UNIVERSAL_LIFE",                      // 萬用壽險
    TERM_LIFE = "TERM_LIFE",                                // 定期壽險
    INVESTMENT_LINKED = "INVESTMENT_LINKED",                // 投資相連
    ANNUITY = "ANNUITY",                                    // 年金計劃
    REAL_ESTATE = "REAL_ESTATE",                            // 地產保險
    FINANCING = "FINANCING",                                // 融資保險
    OTHERS = "OTHERS",                                      // 其它
}

export enum ProductStatus {
    DISCONTINUED = "DISCONTINUED", // 停售
    SELLING = "SELLING" // 在售
}


export enum ProductCommonType {
    LIFE = "LIFE",
    GI = "GI",
    MPF = "MPF"
}

export enum ProductType {
    BASIC = "BASIC", // 停售
    RIDER = "RIDER" // 在售
}

export enum ProductFrequency {
    SINGLE_PAYMENT = "SINGLE_PAYMENT", // 躉繳
    MONTHLY = "MONTHLY", // 月繳
    QUARTERLY = "QUARTERLY", // 季繳
    SEMI_ANNUALLY = "SEMI_ANNUALLY", // 半年繳
    ANNUALLY = "ANNUALLY", // 年繳
}

export enum QuotationFormula {
    PREMIUM_RATE = "PREMIUM_RATE",               // 根據保費率（重疾險，儲蓄險等）
    PREMIUM_TABLE = "PREMIUM_TABLE"              // 根據保費表（醫療險適用）
}

export enum MedicalRegion {
    WORLDWIDE = "WORLDWIDE", // 全球
    WORLDWIDE_EXCEPT_US = "WORLDWIDE_EXCEPT_US", // 全球除去美國
    ASIA = "ASIA", // 亞洲
    GREATER_CHINA = "GREATER_CHINA", // 大中華
    GREATER_CHINA_HMT = "GREATER_CHINA_HMT", // 大中華（港澳臺）
}

export enum SupplementaryBenefit {
    OUTPATIENT = "OUTPATIENT", // 門診
    MATERNITY = "MATERNITY", // 產科
    DENTAL = "DENTAL", // 牙科
    SMM = "SMM", // 額外醫療保障
    SMMP = "SMMP", // 特級額外醫療保障
}

export enum MedicalLevel {
    BASIC_WARD = "BASIC_WARD", // 基礎病房
    WARD = "WARD", // 普通病房
    SEMI_PRIVATE = "SEMI_PRIVATE", // 半私家病房
    PRIVATE = "PRIVATE", // 標準私家病房
    DELUXE = "DELUXE", // 豪華病房
    VIP = "VIP", // VIP
    SUITE = "SUITE", // 套房
}

/*
 資訊實體
*/


export enum ReleaseType {
    ADMINISTRATION_NOTICE = "ADMINISTRATION_NOTICE", // 行政通知
    PRODUCT_NEWS = "PRODUCT_NEWS", // 產品動態
    GENERAL_INFO = "GENERAL_INFO", //一般資訊
    PRODUCT_PROMOTION = "PRODUCT_PROMOTION" // 產品優惠
}

export class Deductible {
    currency: Currency;
    amounts: string[];
}

export class MedicalOptions {
    regions: MedicalRegion[];
    deductibles: Deductible[];
    levels: MedicalLevel[];
    supplementaryBenefits: SupplementaryBenefit[];
}

export class ProductSearch extends PagedReq {
    code: string;
    name: string;
    type: ProductType;
    categoryCode: ProductCategoryCode;
    companyCode: string;
    status: ProductStatus;
    standard: boolean;
    comparable: boolean;
    quotable: boolean;
    withdrawalable: boolean;
    hot: boolean;
    proposalRPASupported: boolean;
    financialPlanSupported: boolean;
    hasBrochure: boolean;
    region: ProductCompanyRegion;
    premiumFinancingSupported: boolean;
    premiumOffsetSupported: boolean;
    proposalPrepaymentSupported: boolean;
}

export class Product {
    code: string;
    categoryCode: ProductCategoryCode;
    category: ProductCategory;
    commonType: ProductCommonType;
    type: ProductType;
    companyCode: string;
    company: ProductCompany;
    name: string;
    @Transform((value) => plainToInstance(I18n, Object.assign(new I18n(), value.value)))
    nameI18n: I18n;
    @Transform((value) => plainToInstance(I18n, Object.assign(new I18n(), value.value)))
    shortNameI18n: I18n; // 簡稱
    @Transform((value) => plainToInstance(I18n, Object.assign(new I18n(), value.value)))
    complianceNameI18n: I18n; // 合規名稱
    @Transform((value) => plainToInstance(I18n, Object.assign(new I18n(), value.value)))
    fullNameI18n: I18n;
    desc: string;
    descI18n: I18n;
    logo: string;
    rank: number;
    standard: boolean;
    status: ProductStatus;
    premiumTerms: string[];
    currencies: Currency[];
    frequencies: ProductFrequency[];
    comparable: boolean; // 是否可对比
    quotable: boolean; // 是否可试算
    withdrawalable: boolean; // 是否適用提取計劃
    quotationFormula: QuotationFormula;
    medicalOptions: MedicalOptions;
    hot: boolean; // 是否热门产品
    proposalRPASupported: boolean; // 是否支持計劃書機器人
    financialPlanSupported: boolean; // 是否支持製作規劃書
    proposalPrepaymentSupported: boolean; // 是否支持計劃書预缴
    premiumFinancingSupported: boolean; // 是否支持保费融资
    premiumOffsetSupported: boolean; // 是否支持保费扣除
    hasBrochure: boolean; // 是否有小冊子
    @Transform((value) => plainToArray(Material, value.value))
    files: Material[];
    fileTags: string[];
    policyPeriod: string;
    launchDate: number;
    discontinuationDate: number;
    internalRemark: string;

    @Transform((value) => {
        let feature = plainToInstance(ProductFeature, Object.assign(new ProductFeature(), value.value));
        if (feature.proposalAmountTip === undefined || feature.proposalAmountTip === null) {
            feature.proposalAmountTip = new I18n();
        }
        return feature;
    })
    features: ProductFeature;
    region: ProductCompanyRegion;
    checked: boolean = false;
    quotationVersion: string;
}

export enum ProductTagKey {
    LIFE = "LIFE", // 人壽
    MEDICARE = "MEDICARE", // 醫療
    EDUCATION = "EDUCATION", // 教育
    RETIREMENT = "RETIREMENT", // 教育
}

export class ProductTagSearch extends PagedReq {
}

export class ProductTag {
    key: ProductTagKey;
    name: string;
    nameI18n: I18n;
    rank: number;
}

export class ProductAttributes {
    attribute: string;
    name: string;
    value: string;
    valueI18n: I18n;
}

export class ProductAttributeGroup {
    key: string;
    name: string;
    rank: number;
    attributes: ProductAttributes[];
}

export class ProductProperty {
    code: string;
    key: string;
    name: string;
    nameI18n: I18n;
    value: string;
    valueI18n: I18n;
}

export class ProductPropertyGroup {
    key: string;
    name: string;
    nameI18n: I18n;
    rank: number;
    properties: ProductProperty[];
}

export class ProductPropertyGroupSearch extends PagedReq {
}

export enum ProductRestrictionType {
    AGE = "AGE", // 年齡
    PREMIUM = "PREMIUM", // 保費
    SUM_INSURED = "SUM_INSURED", // 保額
    WITHDRAWAL = "WITHDRAWAL", // 提取計劃
}

export class ProductRestrictionSearch extends PagedReq {
    code: string;
    premiumTerm: string;
    type: ProductRestrictionType;
}

export class ProductRestriction {
    code: string;
    productName: string;
    premiumTerm: string;
    type: ProductRestrictionType;
    restriction: string;
    description: string[];
}

export class KnowledgeType {
    id: number;
    name: string;
    nameI18n: I18n;
    rank: number;
}

export class KnowledgeSearch extends PagedReq {
    typeId: number;
    keyword: string;
    companyCode: string;
}

export class Attachments {
    fileName: string;
    filePath: string;
    uploadTime: number;
    author: string;
}

export enum KnowledgeMediaType {
    HTML = 'HTML',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO'
}

export class Knowledge {
    id: number;
    typeId: number;                 /// 類型id
    typeName: string;               /// 類型名稱
    mediaType: KnowledgeMediaType;  /// 媒体类型
    title: string;                  /// 標題
    content: string;                /// 內容
    cover: string;                  /// 封面
    tags: string[];                 /// 標籤列表
    companyCode: string;            /// 公司碼
    productCode: string;            /// 產品碼
    productName: string;            /// 產品名稱
    visits: number;                 /// 查看次數
    shares: number;                 /// 分享次數
    issueDate: number;              /// 發佈時間
    notified: boolean;              /// 是否已发送通知
    isTop: boolean;              /// 是否置顶
}

export class InformationSearch extends PagedReq {
    id: string;
    keyword: string;
    tags: string;
    type: ReleaseType;
    beginTime: string;
    endTime: string;
}

export class Information extends Model {
    accountName: string;
    addTime: number;
    startEffectiveDate: number;
    endEffectiveDate: number;
    title: string;
    companyName: string;
    content: string;
    attachments: Material[];
    type: ReleaseType;
    tags: string[];
    productCodes: string[];
    productNames: string[];
    companyCode: string;
    link: string;
    cover: string;
    deleted: boolean;

    checked: boolean = false;
}

export class InformationTags {
    name: string[];
}

export class DiscontinuedProductFilterReq {
    productCodes: string[];
}

export class DiscontinuedProductFilterResp {
    products: Product[];
}

export class Proposal extends Model {
    instance: string;
    proposalId: string;
    productCode: string;
    productName: string;
    premiumTerm: string;
    companyCode: string;
    special: string;
    name: string;
    gender: string;
    tag: string;
    age: number;
    premium: number;
    financialPlanId: number;
    sumInsured: number;
    currency: Currency;
    smokeCondition: string;
    relationship: string;
    url: Material;
    cleaned: boolean;
    abnormal: boolean;
    remark: string;
    language: string;
    loading: boolean;
    deleted: boolean;
    failure: boolean;
    doable: boolean;
    anchor: string; // 锚(计划书的指纹)
    statusNote: string;
    status: string;
    region: string;
    frequency: string;
    processingMode: string; //ROBOT :RPA機器人 MANUAL :人工
    reduceBasic: string;
    withdrawals: Withdrawals[];
    riders: Riders[];
    medicalOption: MedicalOption;
    financialPlanSupported: boolean;  // 是否支持规划书
}

export class Withdrawals {
    name: string; // 提取计划名
    from: number; // 从哪一保单年度
    to: number; // 至哪一保单年度
    amount: number; // 金额
    max: boolean; // 是否是最大金额
}

export class Riders {
    productCode: string; // 提取计划名
    sumAssured: number; // 从哪一保单年度
    premium: number; // 至哪一保单年度
    medicalPlan: MedicalPlan; // 金额
}

export class MedicalPlan {
    region: string;
    deductible: number;
    medicalLevel: Deductible;
    supplementaryBenefits: SupplementaryBenefit[];
}

export class MedicalOption {
    region: string;
    deductible: number;
    level: string;
    supplementaryBenefits: SupplementaryBenefit[];
}

export class ProposalSearch extends PagedReq {
    id: number;
    companyCode: string; // 公司碼
    productCode: string; // 產品碼
    productName: string; // 產品名稱
    premiumTerm: string; // 年期
    instance: string; // 客户实例
    proposalId: string; // 计划书编号
    startCreateDate: string;
    endCreateDate: string;
    gender: string; // 性別
    age: number; // 年齡
    currency: Currency; // 貨幣
    smokeCondition: string; // 是否吸煙
    cleaned: boolean;   // 已清理
    abnormal: boolean; // 异常
}

export class ProposalStatistic {
    total: number;
    cleaned: number;
    uncleaned: number;
    abnormal: number;
}

export class ProposalCompareAttribute {
    attribute: string; // 对比项key
    score: string; // 分数
}

export class ProposalCompareAttributeScore {
    proposalId: string; // 计划书编号
    attributes: ProposalCompareAttribute[]; // 对比项
}

export class ProposalCompare extends Model {
    instance: string; // 客户实例
    bid: number; // 对应 LifeBee 编号
    name: string; // 客户姓名
    categoryCode: string; // 保险种类
    proposalIds: string[]; // 计划书编号列表
    groups: string[]; // 隐藏的对比分组
    attributes: string[]; // 隐藏的对比项
    accountName: string; // 账号名
    attributeScores: ProposalCompareAttributeScore; // 对比项对应评分
    status: ProposalCompareStatus; // 状态
    urlHk: string; // 繁体pdf地址
    urlCn: string; // 简体pdf地址
    urlUs: string; // 英文pdf地址
    cover: string; // 封面地址
    asterisk: boolean; // 产品、公司名是否打星
}

export enum ProposalCompareStatus {
    PENDING = "PENDING",
    COMPLETED = "COMPLETED",
    FAIL = "FAIL",
    DELETED = "DELETED"
}

export class ProposalCompareSearch extends PagedReq {
    instance: string;
    bid: number;
}

export class ProposalCustom extends Model {
    instance: string; // 客户实例
    bid: number; // 对应 LifeBee 编号
    accountName: string; // 账号名
    name: string; // 客户姓名
    status: ProposalCustomStatus; // 状态
    url: Material; // url
    proposalId: string; // 计划书id
    category: ProposalCustomCategory; // 模板分类
}

export enum ProposalCustomStatus {
    PENDING = "PENDING",
    COMPLETED = "COMPLETED",
    FAIL = "FAIL",
    DELETED = "DELETED"
}

export enum ProposalCustomCategory {
    STANDARD = "STANDARD",
}

export class ProposalCustomSearch extends PagedReq {
    instance: string;
    bid: number;
}

export class ExcelReport {
    errorDetails: ExcelError[];
    errorRecords: number;
    logicErrorRecords: number;
    storageRecords: number;
    totalRows: number;
    validRows: number;
}

export class ExcelError {
    cellDataType: string;
    column: string;
    data: string;
    exception: string;
    message: string;
    row: number;
    sheetName: string;
}

